import { useQuery } from '@tanstack/vue-query'
import { ROLES_QUERY_KEYS } from '~/constants/queryKeys/rolesQueryKeys'

export function useUserRole() {
  const { $api } = useNuxtApp()
  const profileStore = useProfileStore()
  const { organisationId } = storeToRefs(profileStore)

  const { data: userRoleData } = useQuery({
    queryKey: ROLES_QUERY_KEYS.GET_USER_ROLE(organisationId.value as string),
    queryFn: () =>
      $api.core.roles.getUserRoleForOrganization(organisationId.value!),
    select(data) {
      return data.data
    },
    enabled: computed(() => !!organisationId.value),
  })

  const currentRoleName = computed(() => {
    if (!userRoleData.value) return null
    return userRoleData.value.name
  })
  const currentUserPermissions = computed(() => {
    if (!userRoleData.value) return []
    return userRoleData.value.permissions
  })

  const currentUserPermissionsIds = computed(() =>
    currentUserPermissions.value.map((perm) => perm.id),
  )

  return {
    currentRoleName,
    currentPermissionsIds: currentUserPermissionsIds,
  }
}
